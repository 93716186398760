import React from 'react';
import styled from 'styled-components';

const AsciiArtContainer = styled.div`
  font-family: monospace;
  white-space: pre;
  line-height: 1;
  text-align: center;
  color: #9A00F5;
  margin-bottom: 20px;
`;

export const AsciiArt: React.FC = () => (
  <AsciiArtContainer>
    {`
    ________  ________  ________  ________  ___  ___  ________  ___  ___  ________  _______      
    |\\   ____\\|\\   __  \\|\\   __  \\|\\   __  \\|\\  \\|\\  \\|\\   __  \\|\\  \\|\\  \\|\\   ____\\|\\  ___ \\     
    \\ \\  \\___|\\ \\  \\|\\  \\ \\  \\|\\  \\ \\  \\|\\  \\ \\  \\\\\\  \\ \\  \\|\\  \\ \\  \\\\\\  \\ \\  \\___|\\ \\   __/|    
     \\ \\  \\  __\\ \\  \\\\\\  \\ \\  \\\\\\  \\ \\   ____\\ \\   __  \\ \\  \\\\\\  \\ \\  \\\\\\  \\ \\  \\    \\ \\  \\_|/__  
      \\ \\  \\|\\  \\ \\  \\\\\\  \\ \\  \\\\\\  \\ \\  \\___|\\ \\  \\ \\  \\ \\  \\\\\\  \\ \\  \\\\\\  \\|____|\\  \\ \\  \\_|\\ \\ 
       \\ \\_______\\ \\_______\\ \\_______\\ \\__\\    \\ \\__\\ \\__\\ \\_______\\ \\_______\\____\\_\\  \\ \\_______\\
        \\|_______|\\|_______|\\|_______|\\|__|     \\|__|\\|__|\\|_______|\\|_______|\_________\\|_______|
                                                                              \\|_________|         
    `}
  </AsciiArtContainer>
);